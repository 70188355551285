<template>
    <div>
        <infoFormMessage
                v-if="typeof result.infoFormMessage != 'undefined'"
                :message="result.infoFormMessage"
                :result="result"
        ></infoFormMessage>
        <v-card class="mx-auto" max-width="600" outlined>
            <v-toolbar color="primary" dark flat>
                <v-toolbar-title>{{ result.pageTitle }}</v-toolbar-title>
            </v-toolbar>
            <v-spacer></v-spacer>
            <v-form ref="userRegistration" v-model="isValid" action="javascript:void(0)">
                <v-list-item>
                    <v-list-item-content>
                        <div class="body-2 ml-6">
                            <v-row>
                                <v-col :cols="11">
                                    <fieldsSingleRows
                                            @field-value-changed="checkInput(...arguments)"
                                            field="c_email"
                                            :templateContent="result"
                                            :valueLine="result.valueLine"
                                    ></fieldsSingleRows>
                                    <fieldsSingleRows
                                            @field-value-changed="checkInput(...arguments)"
                                            field="captcha_value"
                                            :templateContent="result"
                                            :valueLine="result.valueLine"
                                    ></fieldsSingleRows>
                                </v-col>
                            </v-row>
                        </div>
                    </v-list-item-content>
                </v-list-item>

                <v-card-actions class="max-auto">
                    <fieldsButtons
                            :result="result"
                            @submitted="submitForm(...arguments)"
                            :isValid="isValid"
                            :routerLink="{ to:'?f=global&f2=login',text:language.componentLabel.labelB2LoginPage,class:'back2Login mt-3'}"
                    ></fieldsButtons>
                </v-card-actions>
            </v-form>
        </v-card>
        <div class="pt-10"></div>
    </div>
</template>
<style scoped>
    .relativeTopN12 {
        position: relative;
        top: -12px;
    }
</style>
<script>
  import fieldsSingleRows from "@/commonComponents/fieldsSingleRows.vue";
  import fieldsButtons from "@/commonComponents/fieldsButtons.vue";
  import infoFormMessage from "@/commonComponents/infoFormMessage.vue";

  export default {
    components: {
      infoFormMessage,
      fieldsSingleRows,
      fieldsButtons,
    },
    props: ["result"],
    data: function () {
      return {
        isValid: false,
      };
    },
    methods: {
      submitForm(functionName) {
        console.log(functionName);
        let gRR = window.grecaptcha.getResponse();

        if (gRR.length == 0) {
          this.$store.commit("dialogButtonsLoading", false);
          this.$notify({
            group: "foo",
            text: "Please verify you are human!",
            duration: 1000,
            type: "error",
            speed: 600,
          });
          // this.isValid = false;
        } else if (this.$refs.userRegistration.validate()) {
          this.ajaxSubmit(
            functionName,
            this.$refs.userRegistration.$el,
            "",
            this.result
          );
          /*this.$notify({
            group: "foo",
            text: "Email send with Temporary password for reset!",
            duration: 1000,
            type: "success",
            speed: 600,
          });*/
          this.isValid = false;
        }
      },
      checkInput(field, value) {
        if (typeof this.result.valueLine == "undefined") {
          this.$set(this.result, "valueLine", {});
        }
        this.$set(this.result.valueLine, field, value);
        this.$emit("dialog-result-changed", this.result);
      },
    },
  };
</script>