<template>
<div>
   <v-alert
          :type="type"
          style="cursor: pointer;"
          :title="title"
   ><div v-html="message"></div></v-alert>
</div>
</template>
<script>
export default {
  props: ["message","type","title"],

  methods:{
/*    alertMessageType(type){
      console.log(type);
      var result = "error"; //default
      if(typeof type!='undefined' ){
        if(type=='errorInfo'){
          result="error";
        }else if(type=='successInfo'){
          result="success";
        }
      }
      return result;
    }*/
  }
};
</script>